import { Session } from '@supabase/supabase-js'
import { useEffect } from 'react'
import { atom, useRecoilState } from 'recoil'

import { supabase } from '@/lib/supabase'

export const sessionState = atom<Session>({
    key: 'sessionState',
    default: null,
})

export const useSession = () => {
    const [session, setSession] = useRecoilState(sessionState)

    useEffect(() => {
        const session = supabase.auth.session()
        setSession(session)

        const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
            setSession(session)
            await fetch('/api/auth', {
                method: 'POST',
                body: JSON.stringify({ event, session }),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        })
        return () => {
            authListener?.unsubscribe()
        }
    }, [])

    return { session }
}
