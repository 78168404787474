/* eslint-disable */
// prettier-ignore
export const pagesPath = {
  about: {
    $url: (url?: { hash?: string }) => ({ pathname: '/about' as const, hash: url?.hash })
  },
  actors: {
    _id: (id: string | number) => ({
      $url: (url?: { hash?: string }) => ({ pathname: '/actors/[id]' as const, query: { id }, hash: url?.hash })
    })
  },
  admin: {
    actors: {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/admin/actors/[id]' as const, query: { id }, hash: url?.hash })
      }),
      edit: {
        _id: (id: string | number) => ({
          $url: (url?: { hash?: string }) => ({ pathname: '/admin/actors/edit/[id]' as const, query: { id }, hash: url?.hash })
        })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/admin/actors' as const, hash: url?.hash })
    },
    projects: {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/admin/projects/[id]' as const, query: { id }, hash: url?.hash })
      })
    },
    videos: {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/admin/videos/[id]' as const, query: { id }, hash: url?.hash })
      })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/admin' as const, hash: url?.hash })
  },
  auth: {
    forgot: {
      $url: (url?: { hash?: string }) => ({ pathname: '/auth/forgot' as const, hash: url?.hash })
    },
    login: {
      $url: (url?: { hash?: string }) => ({ pathname: '/auth/login' as const, hash: url?.hash })
    },
    signup: {
      $url: (url?: { hash?: string }) => ({ pathname: '/auth/signup' as const, hash: url?.hash })
    }
  },
  sitemap_xml: {
    $url: (url?: { hash?: string }) => ({ pathname: '/sitemap.xml' as const, hash: url?.hash })
  },
  users: {
    orders: {
      $url: (url?: { hash?: string }) => ({ pathname: '/users/orders' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/users' as const, hash: url?.hash })
  },
  $url: (url?: { hash?: string }) => ({ pathname: '/' as const, hash: url?.hash })
}

// prettier-ignore
export type PagesPath = typeof pagesPath
