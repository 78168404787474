import Image from 'next/image'
import Link from 'next/link'
import Gravatar from 'react-gravatar'

import Logo from '../../assets/logo.png'
import { pagesPath } from '../../utils/$path'

import { useSession } from '@/hooks/useSession'

const Header = () => {
    const { session } = useSession()

    return (
        <div>
            <div className="md:hidden mx-auto"></div>
            <div className="container mx-auto px-4 pt-5 pb-4">
                <div className="flex justify-between items-center">
                    <div>
                        <Link href={pagesPath.$url()}>
                            <a className="cursor-pointer">
                                <Image
                                    src={Logo}
                                    alt="basicincome cinemas"
                                    width={788 / 5.2}
                                    height={213 / 5.2}
                                />
                            </a>
                        </Link>
                    </div>
                    {!session ? (
                        <div className="flex flex-row items-center">
                            <Link href={pagesPath.auth.login.$url()}>
                                <a className="cursor-pointer text-sm mx-2">ログイン</a>
                            </Link>
                            <Link href={pagesPath.auth.signup.$url()}>
                                <a className="cursor-pointer text-sm mx-2">新規登録</a>
                            </Link>
                        </div>
                    ) : (
                        <div className="flex flex-row items-center">
                            <Link href={pagesPath.users.orders.$url()}>
                                <a className="cursor-pointer text-sm mx-4">購入履歴</a>
                            </Link>
                            <Link href={pagesPath.users.$url()}>
                                <a className="cursor-pointer text-sm">
                                    <Gravatar
                                        email={session.user.email}
                                        default={'identicon'}
                                        className="rounded-full border border-gray-400 w-10"
                                    />
                                </a>
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Header
