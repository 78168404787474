import Head from 'next/head'

export default function CommonMeta({
    title = 'ベーシックインカムシネマズ',
    description = '受給者の「葛藤」と「希望」を描くドキュドラマ。ベーシックインカムの実証実証をもとにした映画配信・受給者の支援サービスです。',
}) {
    const title_with_service =
        title === 'ベーシックインカムシネマズ'
            ? 'ベーシックインカムシネマズ'
            : `${title} - ベーシックインカム実証実験ドキュドラマ`
    return (
        <Head>
            <title>{title_with_service}</title>
            <meta name="description" content={description} />
            <meta property="og:title" content={title_with_service} />
            <meta property="og:description" content={description} />
            <meta
                property="og:image"
                content={`https://${process.env.NEXT_PUBLIC_VERCEL_URL}/ogp.png`}
            />
            <meta name="twitter:card" content="summary_large_image" />
        </Head>
    )
}
